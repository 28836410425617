import React from "react";
import '../../styles/Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

const ReportCard = ({ text, date, onClick }) => {
  const navigate = useNavigate(); // Déclarez useNavigate

  const handleClick = () => {

    navigate(`/reports#${date}`); // Remplacez 'date' par l'identifiant approprié pour l'ancre
  };

  return (
    <div 
      className="card-report" 
      onClick={handleClick} // Ajoutez le gestionnaire d'événements de clic ici
    >
      <div className="display-line">
        <FontAwesomeIcon 
          icon={faFilePdf} 
          className="card-report-icon"
        />
        <div className="card-report-titre">Report du {date}</div>
      </div>
    </div>
  );
};

export default ReportCard;
