import React from 'react';

const FilterSection = ({ currentFilter, filterOptions, handleFilterChange, handleAddFilter, filters, handleRemoveFilter }) => {
    return (
        <div className="card-full">
            <h5>Filter by</h5>
            <div className="display-line dropdown">
                <select className="dropdown" name="column" onChange={handleFilterChange} value={currentFilter.column}>
                    <option value="">Select Column</option>
                    <option value="location">Location</option>
                    <option value="team">Team</option>
                </select>
                <select className="dropdown" name="value" onChange={handleFilterChange} value={currentFilter.value} disabled={!currentFilter.column}>
                    <option value="">Select Value</option>
                    {currentFilter.column === 'location' && filterOptions.locations.map((location, index) => (
                        <option key={index} value={location}>{location}</option>
                    ))}
                    {currentFilter.column === 'team' && filterOptions.teams.map((team, index) => (
                        <option key={index} value={team.id}>{team.name}</option>
                    ))}
                </select>
                <button type="button" onClick={handleAddFilter} className="btn-secondary" disabled={!currentFilter.column || !currentFilter.value}>Add Filter</button>
            </div>
            <div className="display-line">
                {filters.map((filter, index) => (
                    <div key={index} className="filter-tag">
                        {filter.column}: {filter.displayValue}
                        <button className="filter-tag-btn" type="button" onClick={() => handleRemoveFilter(index)}>x</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterSection;
