import React, { useState, useEffect } from 'react';
import logoImage from '../assets/logo.jpg';
import '../styles/Assets.css'; // Assurez-vous que ce fichier CSS est bien créé

const Loader = () => {

  return (
    <div className="logo-container">
      <img src={logoImage} alt="Logo" className="rotating-logo" />
    </div>
  );
}

export default Loader;
