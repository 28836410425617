import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/ButtonLogout.css';

const ButtonLogout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Supprimer le token du localStorage ou nettoyer l'état global si nécessaire
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        // Rediriger l'utilisateur vers la page de connexion ou la page d'accueil
        navigate('/login'); // Utilise useNavigate pour la redirection
    };

    return (
        <button onClick={handleLogout} className="logout-button">
            Log Out
        </button>
    );
};

export default ButtonLogout;
