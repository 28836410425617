import React from 'react';

const DateSection = ({ startDate, endDate, groupOption, handleStartDateChange, handleEndDateChange, handleGroupChange, handleApplyDates }) => {
    return (
        <div className="card-full">
            <h5>Select Date Range & Group By</h5>
            <div className="display-line">
                <div className="display-line">
                    
                    <p>Start Date</p>
                    <input
                        type="date"
                        className="form-input"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="display-line">
                    <p>End Date</p>
                    <input
                        type="date"
                        className="form-input"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className="display-line dropdown">
                    <p>Group By</p>
                    <select value={groupOption} onChange={handleGroupChange} className='dropdown'>
                        <option value="month">Month</option>
                        <option value="quarter">Quarter</option>
                    </select>
                </div>
                <button
                    type="button"
                    onClick={handleApplyDates}
                    className="btn-secondary"
                >
                    Valider
                </button>
            </div>
        </div>
    );
};

export default DateSection;
