import React from 'react';
import { Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

const NineBoxMatrix = ({ chartData }) => {
    // Taille maximale des bulles après normalisation
    const maxBubbleSize = 45;

    // Trouver le rayon maximum dans les données d'origine
    const maxR = Math.max(...chartData.datasets.flatMap(dataset => dataset.data.map(point => point.r)));

    // Normaliser les tailles des bulles et stocker le rayon original
    const normalizedData = {
        ...chartData,
        datasets: chartData.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.map(point => ({
                ...point,
                originalR: point.r,  // Stocker le rayon original
                r: (point.r * maxBubbleSize) / maxR  // Normaliser le rayon
            }))
        }))
    };

    return (
        <div className="card-full">
            <Bubble data={normalizedData} options={{
                scales: {
                    x: {
                        type: 'linear',
                        position: 'bottom',
                        title: {
                            display: true,
                            text: 'Potentiel',
                            color: '#911',
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        },
                        ticks: {
                            callback: function(value) {
                                switch (value) {
                                    case 1: return 'Faible';
                                    case 2: return 'Moyenne';
                                    case 3: return 'Élevée';
                                    default: return '';
                                }
                            }
                        }
                    },
                    y: {
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: 'Performance',
                            color: '#911',
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        },
                        ticks: {
                            callback: function(value) {
                                switch (value) {
                                    case 1: return 'Faible';
                                    case 2: return 'Moyenne';
                                    case 3: return 'Élevée';
                                    default: return '';
                                }
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                const originalValue = context.raw.originalR;  // Accéder au rayon original
                                return `${context.dataset.label}: ${originalValue}`;
                            }
                        }
                    },
                    annotation: {
                        annotations: {
                            box1: {
                                type: 'box',
                                xMin: 0,
                                xMax: 1,
                                yMin: 0,
                                yMax: 1,
                                backgroundColor: '#FC123D50'
                            },
                            label1: {
                                type: 'label',
                                xValue: 0.5,
                                yValue: 0.5,
                                color: 'black',
                                content: 'Talent Risk',
                            },
                            box2: {
                                type: 'box',
                                xMin: 1,
                                xMax: 2,
                                yMin: 0,
                                yMax: 1,
                                backgroundColor: '#F3916650'
                            },
                            label2: {
                                type: 'label',
                                xValue: 1.5,
                                yValue: 0.5,
                                color: 'black',
                                content: 'Inconsistent Player',
                            },
                            box3: {
                                type: 'box',
                                xMin: 2,
                                xMax: 3,
                                yMin: 0,
                                yMax: 1,
                                backgroundColor: '#70CCCC50'
                            },
                            label3: {
                                type: 'label',
                                xValue: 2.5,
                                yValue: 0.5,
                                color: 'black',
                                content: 'Rough Diamond',
                            },
                            box4: {
                                type: 'box',
                                xMin: 0,
                                xMax: 1,
                                yMin: 1,
                                yMax: 2,
                                backgroundColor: '#F3916650'
                            },
                            label4: {
                                type: 'label',
                                xValue: 0.5,
                                yValue: 1.5,
                                color: 'black',
                                content: 'Solid pro',
                            },
                            box5: {
                                type: 'box',
                                xMin: 0,
                                xMax: 1,
                                yMin: 2,
                                yMax: 3,
                                backgroundColor: '#70CCCC50'
                            },
                            label5: {
                                type: 'label',
                                xValue: 0.5,
                                yValue: 2.5,
                                color: 'black',
                                content: 'High pro',
                            },
                            box6: {
                                type: 'box',
                                xMin: 1,
                                xMax: 2,
                                yMin: 1,
                                yMax: 2,
                                backgroundColor: '#70CCCC50'
                            },
                            label6: {
                                type: 'label',
                                xValue: 1.5,
                                yValue: 1.5,
                                color: 'black',
                                content: 'Key player',
                            },
                            box7: {
                                type: 'box',
                                xMin: 1,
                                xMax: 2,
                                yMin: 2,
                                yMax: 3,
                                backgroundColor: '#7AE77550'
                            },
                            label7: {
                                type: 'label',
                                xValue: 1.5,
                                yValue: 2.5,
                                color: 'black',
                                content: 'Current Star',
                            },
                            box8: {
                                type: 'box',
                                xMin: 2,
                                xMax: 3,
                                yMin: 1,
                                yMax: 2,
                                backgroundColor: '#7AE77550'
                            },
                            label8: {
                                type: 'label',
                                xValue: 2.5,
                                yValue: 1.5,
                                color: 'black',
                                content: 'Future Star',
                            },
                            box9: {
                                type: 'box',
                                xMin: 2,
                                xMax: 3,
                                yMin: 2,
                                yMax: 3,
                                backgroundColor: '#0F950850'
                            },
                            label9: {
                                type: 'label',
                                xValue: 2.5,
                                yValue: 2.5,
                                color: 'black',
                                content: 'Consistent Star',
                            },
                        }
                    }
                }
            }} />
        </div>
    );
};

export default NineBoxMatrix;
