import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import OverviewCard from '../../../components/cards/OverviewCard';
// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const InsightAgeDistribution = () => {
    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('Global'); // Commencez par une vision globale
    const promptContext = "Tu vas recevoir des données qui représentent le découpage des salariés par tranche d'âge dans l'entreprise. Elles sont group by différentes propriétés.";
    const processChartData = (data) => {
        let groupData = {};
    
        // Pour la vision globale, utiliser 'Non spécifié' sous 'Sexe'
        if (groupOption === 'Global') {
            groupData = data.Global ? { "Global": data.Global } : {};
        }
         else {
            groupData = data[groupOption];
        }
    
        if (!groupData) {
            console.error('Group data is undefined for option:', groupOption);
            return;
        }
    
        let labels = Object.keys(groupData);
        let effectifMoins25 = [];
        let effectif25_35 = [];
        let effectif35_45 = [];
        let effectif45_55 = [];
        let effectifPlus55 = [];


    
    
        labels.forEach(label => {
            const groupe = groupData[label];
            // Vérifiez si le groupe et les propriétés requises existent avant de les utiliser
            if (groupe && groupe.employe_25moins !== undefined && groupe.employe_25_35 !== undefined && groupe.employe_35_45 !== undefined && groupe.employe_45_55 !== undefined && groupe.employe_55plus !== undefined ) {
                effectifMoins25.push(groupe.employe_25moins);
                effectif25_35.push(groupe.employe_25_35);
                effectif35_45.push(groupe.employe_35_45);
                effectif45_55.push(groupe.employe_45_55);
                effectifPlus55.push(groupe.employe_55plus);

            } else {
                // Si les données ne sont pas disponibles, ajoutez des zéros (ou gérer autrement)
                effectifMoins25.push(0);
                effectif25_35.push(0);
                effectif35_45.push(0);
                effectif45_55.push(0);
                effectifPlus55.push(groupe.employe_55plus);

            }
        });
    
        setChartData({
            labels,
            datasets: [
                {
                    label: '25-',
                    data: effectifMoins25,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: '25-35',
                    data: effectif25_35,
                    backgroundColor: 'rgba(255, 206, 86, 0.5)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                },
                {
                    label: '35-45',
                    data: effectif35_45,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
                {
                    label: '45-55',
                    data: effectif45_55,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: '55+',
                    data: effectifPlus55,
                    backgroundColor: 'rgba(153, 102, 255, 0.5)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
            ]
        });
    };
    
    

    useEffect(() => {
        const storageKey = 'InsightAgeDistribution';
        const savedData = localStorage.getItem(storageKey);
        
        if (savedData) {
            const { data, timestamp } = JSON.parse(savedData);
            const oneDay = 24 * 60 * 60 * 1000;

            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }

        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const url = `${apiUrl}/api/insight/agedistribution/`;

            try {
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data: data, timestamp: new Date() }));
                processChartData(data);

            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, [groupOption]);

    const handleSelectChange = (event) => {
        setGroupOption(event.target.value);
    };



    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                   
                <OverviewCard overviewKey={'OverviewAgeDistribution'}  storageKey={'InsightAgeDistribution'} promptContext={promptContext}/>

                <div className='card-full'>
                    <div className='display-line'>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <h5>Grouper par :</h5>
                        <select className="custom-select" onChange={handleSelectChange} value={groupOption}>
                            <option value="Global">Vue Globale</option>
                            <option value="Sexe">Sexe</option>
                            <option value="Ancienneté">Ancienneté</option>
                            <option value="Equipe">Equipe</option>
                        </select>
                    </div>
                </div>
                <div className='card-full margin-b-40'>
                    {chartData && <Bar data={chartData} options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        },
                    }} />}
                </div>
            </div>
        </div>
    );
};

export default InsightAgeDistribution;
