import React, { useState, useEffect } from 'react';
import Sidebar from "../components/Sidebar";
import colors from '../assets/colors';
import Header from "../components/Header";
import DataCleanCard from '../components/cards/DataCleanCard';

const DataClean = () => {
    const [ageCompletion, setAgeCompletion] = useState("0.00%");
    const [salaryCompletion, setSalaryCompletion] = useState("0.00%");
    const [sexeCompletion, setSexeCompletion] = useState("0.00%");
    const [ancienneteCompletion, setAncienneteCompletion] = useState("0.00%");
    const [listeAncienneteIncomplet, setListeAncienneteIncomplet] = useState([]);
    const [listeAgeIncomplet, setListeAgeIncomplet] = useState([]);
    const [listeSalaireIncomplet, setListeSalaireIncomplet] = useState([]);
    const [listeSexeIncomplet, setListeSexeIncomplet] = useState([]);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/api/dataclean/main/`; // Assurez-vous que cet endpoint correspond à celui qui fournit toutes les données de complétion

        const fetchData = async () => {
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données d\'effectif');
                }

                const jsonData = await response.json();
                // Mettre à jour l'état avec les données récupérées
                setAgeCompletion(jsonData.pourcentage_completion_age || "0.00%");
                setAncienneteCompletion(jsonData.pourcentage_completion_anciennete || "0.00%");
                setSalaryCompletion(jsonData.pourcentage_completion_salaire || "0.00%");
                setSexeCompletion(jsonData.pourcentage_completion_sexe || "0.00%");
                setListeAgeIncomplet(jsonData.list_employes_sans_age || []);
                setListeAncienneteIncomplet(jsonData.list_employes_sans_anciennete || []);
                setListeSalaireIncomplet(jsonData.list_employes_sans_salaire || []);
                setListeSexeIncomplet(jsonData.list_employes_sans_sexe || []);
            } catch (error) {
                console.error('Erreur lors de la récupération des données d\'effectif', error);
            }
        };

        fetchData();
    }, []);  // S'assurer que la récupération ne s'exécute qu'une seule fois lors du montage du composant

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <DataCleanCard titre="Âge" pourcentage={ageCompletion} list={listeAgeIncomplet} />
                <DataCleanCard titre="Salaire" pourcentage={salaryCompletion} list={listeSalaireIncomplet} />
                <DataCleanCard titre="Sexe" pourcentage={sexeCompletion} list={listeSexeIncomplet} />
                <DataCleanCard titre="Ancienneté" pourcentage={ancienneteCompletion} list={listeAncienneteIncomplet} />
            </div>
        </div>
    );
};

export default DataClean;
