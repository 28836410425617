import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RisqueDepart from '../../pages/insights/RisqueDepart';

import InsightLeaveReasons from '../../pages/insights/InsightLeaveReasons';
import InsightAgeDistribution from '../../pages/insights/Diversity/InsightAgeDistribution';

const RoutesInsight = () => (
  <Routes>
        <Route path="risquedepart" element={<RisqueDepart/>}/>
        <Route path="leave-reasons" element={<InsightLeaveReasons/>}/>
        <Route path="agedistribution" element={<InsightAgeDistribution/>}/>


      

  </Routes>
);

export default RoutesInsight;
