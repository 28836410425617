import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/Graph.css";
import OverviewCard from '../../components/cards/OverviewCard';
// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const RisqueDepart = () => {
    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('Global'); // Commencez par une vision globale
    const promptContext = "Tu vas recevoir des données qui représentent les risques de départs de l'entreprise parmi les employés. Elles sont group by différentes propriétés.";
    const processChartData = (data) => {
        let groupData = {};
    
        // Pour la vision globale, utiliser 'Non spécifié' sous 'Sexe'
        if (groupOption === 'Global') {
            groupData['Global'] = data.Sexe["Non spécifié"];
        }
        else if (groupOption === 'Sexe') {
            // Pour 'Sexe', excluez 'Non spécifié' en copiant uniquement les catégories 'M' et 'F'
            groupData = {
                'M': data.Sexe['M'] || [],
                'F': data.Sexe['F'] || [],
            }; 
        } else {
            groupData = data[groupOption];
        }
    
        if (!groupData) {
            console.error('Group data is undefined for option:', groupOption);
            return;
        }
    
        let labels = Object.keys(groupData);
        let risqueFaibleData = [];
        let risqueMoyenData = [];
        let risqueEleveData = [];
    
        labels.forEach(label => {
            const groupe = groupData[label];
            // Vérifiez si le groupe et les propriétés requises existent avant de les utiliser
            if (groupe && groupe.risque_faible !== undefined && groupe.risque_moyen !== undefined && groupe.risque_eleve !== undefined) {
                risqueFaibleData.push(groupe.risque_faible);
                risqueMoyenData.push(groupe.risque_moyen);
                risqueEleveData.push(groupe.risque_eleve);
            } else {
                // Si les données ne sont pas disponibles, ajoutez des zéros (ou gérer autrement)
                risqueFaibleData.push(0);
                risqueMoyenData.push(0);
                risqueEleveData.push(0);
            }
        });
    
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Risque Faible',
                    data: risqueFaibleData,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Risque Moyen',
                    data: risqueMoyenData,
                    backgroundColor: 'rgba(255, 206, 86, 0.5)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Risque Élevé',
                    data: risqueEleveData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ]
        });
    };
    
    

    useEffect(() => {
        const storageKey = 'InsightRisqueDepart';
        const savedData = localStorage.getItem(storageKey);
        
        if (savedData) {
            const { data, timestamp } = JSON.parse(savedData);
            const oneDay = 24 * 60 * 60 * 1000;

            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }

        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const url = `${apiUrl}/api/insight/risque`;

            try {
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data: data, timestamp: new Date() }));
                processChartData(data);

            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, [groupOption]);

    const handleSelectChange = (event) => {
        setGroupOption(event.target.value);
    };
    const getChartTitle = () => {
        switch (groupOption) {
            case 'Âge': return 'Risque de départ par Âge';
            case 'Ancienneté': return 'Risque de départ par Ancienneté';
            case 'Sexe': return 'Risque de départ par Sexe';
            case 'Equipe': return 'Risque de départ par Équipe';
            case 'Localisation': return 'Risque de départ par Localisation';
            default: return 'Analyse des risques de départ';
        }
    };


    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <h4>{getChartTitle()}</h4>
                <OverviewCard overviewKey={'OverviewInsightRisqueDepart'}  storageKey={'InsightRisqueDepart'} promptContext={promptContext}/>

                <div className='card-full'>
                    <div className='display-line'>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <h5>Grouper par :</h5>
                        <select className="custom-select" onChange={handleSelectChange} value={groupOption}>
                            <option value="Global">Vue Globale</option>
                            <option value="Âge">Âge</option>
                            <option value="Ancienneté">Ancienneté</option>
                            <option value="Sexe">Sexe</option>
                            <option value="Equipe">Equipe</option>
                            <option value="Localisation">Localisation</option>
                        </select>
                    </div>
                </div>
                <div className='card-full margin-b-40'>
                   
                    {chartData && <Bar data={chartData} options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        },
                    }} />}
                </div>
            </div>
        </div>
    );
};

export default RisqueDepart;
