import React, { useState, useEffect } from 'react';
import InsightCard from '../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';

const InsightTurnoverAtRisk = () => {
    const [riskData, setRiskData] = useState({ employes_risque_faible: 0, employes_risque_moyen: 0, employes_risque_eleve: 0 });
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const navigate = useNavigate();

    const handleOnClick = () => {
      navigate('/insight/risquedepart'); 
     };
    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/api/retention/riskdistrib/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données de distribution de risque');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('riskData', JSON.stringify(dataToStore));
            setRiskData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données de distribution de risque', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('riskData');
        if (storedData) {
            const { data, lastUpdated } = JSON.parse(storedData);
            setRiskData(data);
            setLastRefreshDate(lastUpdated);
        } else {
            fetchData();
        }
    }, []);

    const chartData = {
        labels: ['Risque Faible', 'Risque Moyen', 'Risque Élevé'],
        datasets: [{
            label: 'Nombre d\'employés',
            data: [riskData.employes_risque_faible, riskData.employes_risque_moyen, riskData.employes_risque_eleve],
            backgroundColor: [
                'rgba(75, 192, 192, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(255, 99, 132, 0.6)'
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)'
            ],
            borderWidth: 1
        }]
    };

    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Cache la légende
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    // Préparation du graphique en barres comme un élément React
    const barChart = (
        <Bar data={chartData} options={chartOptions} height={100} width={300} />
    );

    // Mise à jour du subtext pour afficher le nombre d'employés à risque élevé
    const updatedSubtext = `Number of employees at high risk: ${riskData.employes_risque_eleve}`;

    return (
        <InsightCard
            text="Turnover Risk Analysis "
            subtext={updatedSubtext}
            data={barChart}
            onClick={handleOnClick}
        />
    );
};

export default InsightTurnoverAtRisk;
