import React from 'react';
import '../../styles/ButtonSidebar.css'; // Assurez-vous de créer ce fichier CSS pour styliser le bouton

const ButtonReset = () => {
    const handleReset = () => {
      // Sauvegarder le token
      const token = localStorage.getItem('token');
  
      // Effacer tout le localStorage
      localStorage.clear();
  
      // Restaurer le token
      if (token) {
        localStorage.setItem('token', token);
      }
  
      alert('Local data has been reset. Please refresh the page.');
      window.location.reload();
    };
  return (
    <button onClick={handleReset} className="btn-secondary">
      Reset Data
    </button>
  );
};

export default ButtonReset;
