import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import HomeCards from '../cards/HomeCards'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';

// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const TurnoverRate = () => {
  const [turnoverRate, setTurnoverRate] = useState('Chargement...');
  const [lastUpdated, setLastUpdated] = useState('');
  const [chartData, setChartData] = useState(null);
  const navigate = useNavigate();


  const handleOnClick = () => {
    navigate('/graph/turnoverrate'); 
  };
  // Fonction pour récupérer les données de turnover
  const fetchData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      const response = await fetch(`${apiUrl}/api/turnover/semestre`, {
        headers: {
          'Authorization': `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données de turnover');
      }

      const data = await response.json();
      if (data && data.length > 0) {
        const lastEntry = data[data.length - 1];
        setTurnoverRate(lastEntry.taux_turnover);
        setLastUpdated(new Date().toISOString());

        // Préparation des données pour le graphique
        const graphData = {
          labels: data.map(item => item.periode),
          datasets: [{
            label: 'Taux de turnover (%)',
            fill: true,
            backgroundColor: '#EBA49B40', 
            borderColor: '#EBA49B',
            data: data.map(item => parseFloat(item.taux_turnover.replace('%', ''))),
            tension: 0.1
          }]
        };
        setChartData(graphData);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données de turnover', error);
      setTurnoverRate('Erreur de chargement');
    }
  };

  // Appel à fetchData lors du montage du composant
  useEffect(() => {
    fetchData();
  }, []);

  // Options pour le graphique
  const chartOptions = {
    scales: {
      y: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        }
      },
      x: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0 // Continue de masquer les points sur la ligne
      },
      line: {
        borderWidth: 3, // Ajustez si nécessaire

      }
    },
    maintainAspectRatio: false
  };
  
  

  return (
    <HomeCards 
      text={`Average turnover rate for the last semester`}

      kpi={`${turnoverRate}`}
      chart={chartData && <Line data={chartData} options={chartOptions} height={65} width={100} />}
      onClick={handleOnClick}
    />
  );
};

export default TurnoverRate;
