import React, { useState, useEffect } from 'react';
import "../../styles/Home.css";
import "../../styles/Card.css";
import ForecastEmployes from '../../components/kpi/Recrutement/ForecastEmployes';
import CompareRecruit from '../../components/kpi/Recrutement/CompareRecruit';
import InsightStaffLate from '../../components/insights/recrutement/InsightStaffLate';
import InsightStaffLocationLate from '../../components/insights/recrutement/InsightStaffLocationLate';
import TargetCompany from '../../components/kpi/Recrutement/TargetCompany';




const OverviewRecrutement = () => {


    return (

            <div className='line-container-10'>
                <h5>Overview</h5>
                
            <div className='cards-container'>

                <TargetCompany/>
                <ForecastEmployes/>
                <CompareRecruit/>

            </div>
            <div className='line-container-10'>
                <h5>Analytics</h5>
                <div className='cards-container'>
                    <InsightStaffLate/>
                    <InsightStaffLocationLate/>
                    
                </div>
            </div>      
            </div>

    );
};

export default OverviewRecrutement;

