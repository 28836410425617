import React, { useState, useEffect } from 'react';
import InsightCard from '../cards/InsightCard';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';

const InsightTurnoverReasons = () => {
  const [rawData, setRawData] = useState({});
  const [lastRefreshDate, setLastRefreshDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate('/insight/leave-reasons'); 
   };

  const predefinedColors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)', // Utilisé pour "Autre"
  ];

  const fetchData = async () => {
    setIsLoading(true);
    setError('');
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      const response = await fetch(`${apiUrl}/api/historique-turnover/`, {
        headers: {
          'Authorization': `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des motifs de départ');
      }

      const data = await response.json();
      const now = new Date().toISOString();
      localStorage.setItem('leavingReasonsData', JSON.stringify({ data, lastUpdated: now }));
      
      setRawData(data);
      setLastRefreshDate(now);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem('leavingReasonsData');
    if (storedData) {
      const { data, lastUpdated } = JSON.parse(storedData);
      setRawData(data);
      setLastRefreshDate(lastUpdated);
    } else {
      fetchData();
    }
  }, []);

  // Transformation des données brutes en chartData juste avant le rendu
  const transformDataToChartData = (data) => {
    const sortedReasons = Object.entries(data).sort((a, b) => b[1] - a[1]).slice(0, 8);
    return {
      labels: sortedReasons.map(([reason]) => reason),
      datasets: [{
        label: 'Nombre de départs par raison',
        data: sortedReasons.map(([, count]) => count),
        backgroundColor: predefinedColors.slice(0, sortedReasons.length),
        borderColor: predefinedColors.slice(0, sortedReasons.length).map(color => color.replace('0.6', '1')),
        borderWidth: 1,
      }],
    };
  };

  const chartData = transformDataToChartData(rawData);

  const chartOptions = {
    indexAxis: 'y', // Passe le graphique en format horizontal
    scales: {
        x: {
            display: false, // Cache les graduations et le fond de l'axe X
            grid: {
                display: false, // Cache les lignes de grille sur l'axe X
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                display: false, // Cache les lignes de grille sur l'axe Y
            }
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: false // Cache la légende
        }
    },
    elements: {
        bar: {
            borderWidth: 0, // Cache les bordures des barres si nécessaire
        }
    }
};

  return (
    <InsightCard
      text="Analyse des motifs de départ"
      subtext={"Voici les 3 principales raisons"}
      data={<Bar data={chartData} options={chartOptions} height={100} width={300}  />}
      onClick={handleOnClick}
    />
  );
};

export default InsightTurnoverReasons;
