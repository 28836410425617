import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faSort, faSortUp, faSortDown, faExclamationTriangle, faQuestionCircle, faGem, faBriefcase, faArrowUp, faKey, faStar, faTrophy } from '@fortawesome/free-solid-svg-icons';
import "../../styles/Tableau.css";

const ListPerfReview = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loadingButtons, setLoadingButtons] = useState({}); // State to handle loading state of buttons
    const [successButtons, setSuccessButtons] = useState({}); // State to handle success state of buttons
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // State for sorting
    const [bucketFilter, setBucketFilter] = useState(''); // State for bucket filter
    const { reviewId } = useParams();
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const itemsPerPage = 10; // Number of items per page
    const navigate = useNavigate(); // Use useHistory hook

    const fetchAnalysis = useCallback(async () => {
        const analysisUrl = `${apiUrl}/api/perf/employees_with_reviews/${reviewId}/`;

        try {
            const response = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setData(data);

            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };
            localStorage.setItem(`EmployesReviews${reviewId}`, JSON.stringify(dataToStore));
        } catch (error) {
            console.error('Error fetching analysis:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, [apiUrl, token, reviewId]);

    useEffect(() => {
        fetchAnalysis();
    }, [fetchAnalysis]);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const generateDevPlan = async (review_by_personId, employeeId) => {
        setLoadingButtons((prev) => ({ ...prev, [employeeId]: true }));
        setSuccessButtons((prev) => ({ ...prev, [employeeId]: false }));

        const savedData = localStorage.getItem(`EmployesReviews${reviewId}`);
        if (!savedData) {
            console.error('No saved data available');
            setLoadingButtons((prev) => ({ ...prev, [employeeId]: false }));
            return;
        }

        const employee = data.find(emp => emp.id === employeeId);
        if (!employee) {
            console.error('Employee not found');
            setLoadingButtons((prev) => ({ ...prev, [employeeId]: false }));
            return;
        }

        const analysisUrl = `${apiUrl}/api/generate/plandev/`;
        const dataToSend = {
            employee_id: employee.id
        };

        try {
            const analysisResponse = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend)
            });

            if (!analysisResponse.ok) {
                throw new Error('Erreur lors de la récupération de l\'analyse');
            }

            const analysisResult = await analysisResponse.json();
            const devPlanText = analysisResult.response; // Assuming the response contains the devPlan

            // Save the devPlan to the backend
            const saveUrl = `${apiUrl}/devplan/`;
            const saveResponse = await fetch(saveUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    plan_details: devPlanText, // Correspond au champ plan_details du modèle DevPlan
                    review_by_person: review_by_personId
                })
            });

            if (!saveResponse.ok) {
                throw new Error('Erreur lors de la sauvegarde du plan de développement');
            }

            // Update the specific employee's devplan in the state
            setData((prevData) =>
                prevData.map((emp) =>
                    emp.id === employeeId ? { ...emp, devplan: devPlanText, review_status: 'In Progress' } : emp
                )
            );

            setLoadingButtons((prev) => ({ ...prev, [employeeId]: false }));
            setSuccessButtons((prev) => ({ ...prev, [employeeId]: true }));

            // Update the review status to 'In Progress'
            updateReviewStatus(review_by_personId, 'I');
        } catch (error) {
            console.error('Error generating or saving development plan:', error);
            setLoadingButtons((prev) => ({ ...prev, [employeeId]: false }));
        }
    };

    const updateReviewStatus = async (reviewId, newStatus) => {
        const updateUrl = `${apiUrl}/reviews_by_person/${reviewId}/update_status/`;
        try {
            const response = await fetch(updateUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
            if (!response.ok) {
                throw new Error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            setError('Failed to update status. Please try again later.');
        }
    };

    const handleUploadData = (employeeId) => {
        // Logic for handling data upload
        console.log(`Upload data for employee ${employeeId}`);
    };

    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key === null) return 0;
        const direction = sortConfig.direction === 'asc' ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -direction;
        if (a[sortConfig.key] > b[sortConfig.key]) return direction;
        return 0;
    });

    const getReviewLabel = (performance, potentiel) => {
        if (performance <= 2 && potentiel <= 2) {
            return { icon: faExclamationTriangle, label: 'Talent Risk', colorIcone: '#FC123D' };
        } else if (performance <= 2 && potentiel === 3) {
            return { icon: faQuestionCircle, label: 'Inconsistent Player', colorIcone: '#F39166' };
        } else if (performance <= 2 && potentiel >= 4) {
            return { icon: faGem, label: 'Rough Diamond', colorIcone: '#70CCCC' };
        } else if (performance === 3 && potentiel <= 2) {
            return { icon: faBriefcase, label: 'Solid Pro', colorIcone: '#F39166' };
        } else if (performance === 3 && potentiel === 3) {
            return { icon: faKey, label: 'Key Player', colorIcone: '#70CCCC' };
        } else if (performance === 3 && potentiel >= 4) {
            return { icon: faStar, label: 'Future Star', colorIcone: '#7AE775' };
        } else if (performance >= 4 && potentiel <= 2) {
            return { icon: faArrowUp, label: 'High Pro', colorIcone: '#70CCCC' };
        } else if (performance >= 4 && potentiel === 3) {
            return { icon: faStar, label: 'Current Star', colorIcone: '#7AE775' };
        } else if (performance >= 4 && potentiel >= 4) {
            return { icon: faTrophy, label: 'Consistent Star', colorIcone: '#0F9508' };
        }
        return { icon: null, label: '' };
    
    };


    const filteredData = sortedData.filter(employee => {
        const { label } = getReviewLabel(employee.performance, employee.potentiel);
        return bucketFilter === '' || label === bucketFilter;
    });

    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleRowClick = (employeeId) => {
        navigate(`/employe/${employeeId}`);
    };

    const getStatusClassName = (status) => {
        switch (status) {
            case 'Open':
                return 'status-open';
            case 'Closed':
                return 'status-closed';
            case 'In Progress':
                return 'status-in-progress';
            case 'Pending':
                return 'status-pending';
            default:
                return '';
        }
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
                };
            } else {
                return { key, direction: 'asc' };
            }
        });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return faSort;
        return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
    };

    const bucketOptions = [
        { value: '', label: 'All' },
        { value: 'Talent Risk', label: 'Talent Risk' },
        { value: 'Inconsistent Player', label: 'Inconsistent Player' },
        { value: 'Rough Diamond', label: 'Rough Diamond' },
        { value: 'Solid Pro', label: 'Solid Pro' },
        { value: 'Key Player', label: 'Key Player' },
        { value: 'Future Star', label: 'Future Star' },
        { value: 'High Pro', label: 'High Pro' },
        { value: 'Current Star', label: 'Current Star' },
        { value: 'Consistent Star', label: 'Consistent Star' },
    ];

    return (
        <div className="card-full">
            {error ? (
                <div className="error">{error}</div>
            ) : (
                <>
                    <div className='dropdown'>
                        <label htmlFor="bucket-filter">Filter by Bucket: </label>
                        <select
                            id="bucket-filter"
                            value={bucketFilter}
                            onChange={(e) => setBucketFilter(e.target.value)}
                        >
                            {bucketOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <table className="table-custom">
                        <thead>
                            <tr>
                                <th>
                                    Prénom Nom 
                                </th>
                                <th>
                                    Bucket
                                </th>
                                <th onClick={() => handleSort('review_status')}>
                                    Statut <FontAwesomeIcon icon={getSortIcon('review_status')} />
                                </th>
                                <th>
                                    Plan de développement
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((employee, index) => {
                                const { icon, label, colorIcone } = getReviewLabel(employee.performance, employee.potentiel);
                                return (
                                    <tr className='clickable' key={index} onClick={() => handleRowClick(employee.id)}>
                                        <td>{employee.first_name} {employee.last_name}</td>
                                        <td><FontAwesomeIcon icon={icon} style={{ color: colorIcone }}/> {label}</td>
                                        <td className={getStatusClassName(employee.review_status)}>
                                            {employee.review_status || 'N/A'}
                                        </td>
                                        <td>
                                            {employee.devplan ? (
                                                truncateText(employee.devplan, 30)
                                            ) : (
                                                (employee.performance !== null && employee.potentiel !== null) ? (
                                                    <button type='submit' onClick={(e) => {
                                                        e.stopPropagation();
                                                        generateDevPlan(employee.review_id, employee.id);
                                                    }} disabled={loadingButtons[employee.id]}>
                                                        {loadingButtons[employee.id] ? (
                                                            <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
                                                        ) : successButtons[employee.id] ? (
                                                            <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
                                                        ) : 'Générer le plan de développement'}
                                                    </button>
                                                ) : (
                                                    <button type='button' onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleUploadData(employee.id);
                                                    }}>
                                                        Upload data
                                                    </button>
                                                )
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="page-nav">
                        <button
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            className='icon-button'
                        >
                            &lt; Précédent
                        </button>
                        <span className="page-nav police">{currentPage}/{totalPages}</span>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className='icon-button'
                        >
                            Suivant &gt;
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ListPerfReview;
