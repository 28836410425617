import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

const InsightStaffLate = () => {
    const [data, setData] = useState([]);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph : 'Insight Staff Team Late',
            description: 'Teams behind their recruitment goal',
        });
       
        navigate('/staffingplan/insight/lateteam');
    };

    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/openposition/teams_behind_recruitment_goals/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données de distribution de risque');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightStaffLate', JSON.stringify(dataToStore));
            setData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données de distribution de risque', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('InsightStaffLate');
        if (storedData) {
            const { data, lastUpdated } = JSON.parse(storedData);
            setData(data);
            setLastRefreshDate(lastUpdated);
        } else {
            fetchData();
        }
    }, []);

    // Trier les données et prendre les 3 équipes les plus en retard
    const top3Teams = data.sort((a, b) => b.positions_behind - a.positions_behind).slice(0, 3);

    const chartData = {
        labels: top3Teams.map(item => item.team),
        datasets: [{
            label: 'Positions derrière l\'objectif',
            data: top3Teams.map(item => item.positions_behind),
            backgroundColor: [
                'rgba(255, 99, 132, 0.6)', // Rouge
                'rgba(54, 162, 235, 0.6)', // Bleu
                'rgba(75, 192, 192, 0.6)'  // Vert
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(75, 192, 192, 1)'
            ],
            borderWidth: 1
        }]
    };

    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Cache la légende
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    const barChart = (
        <Bar data={chartData} options={chartOptions} height={110} width={300} />
    );

    const topTeam = top3Teams.length > 0 ? top3Teams[0].team : 'N/A';
    const updatedSubtext = `Most delayed team : ${topTeam}`;

    return (
        <InsightCard
            text="Teams missing targets"
            subtext={updatedSubtext}
            data={barChart}
            onClick={handleOnClick}
        />
    );
};

export default InsightStaffLate;
