import React, { useState, useEffect } from 'react';
import '../../styles/Card.css';

const OverviewReportCard = ({ storageKey, overviewKey, promptContext }) => {
    const defaultContent = 'Analysez vos données ici !';
    const initialContent = localStorage.getItem(overviewKey) || defaultContent;
    const [overviewContent, setOverviewContent] = useState(initialContent);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Ajout d'un log pour voir si cette condition est remplie
        console.log('useEffect triggered', overviewKey, localStorage.getItem(overviewKey));
        if (!localStorage.getItem(overviewKey) || localStorage.getItem(overviewKey) === 'No data available') {
            fetchAnalysis(); // Déclenche l'analyse uniquement si overviewKey est vide ou "No data available"
        }
    }, [storageKey, overviewKey]); // Dépendances pour re-déclencher si nécessaire

    const fetchAnalysis = async () => {

        setOverviewContent('Analyse en cours...');

        const savedData = localStorage.getItem(storageKey);
        
        if (!savedData) {
            console.error('No saved data available');
            setOverviewContent('No data available');
            return;
        }

        const analysisUrl = `${apiUrl}/api/overview/data/`;
        const dataToSend = {
            savedData: savedData,
            promptContext: promptContext
        };

        try {
            const analysisResponse = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend)
            });

            if (!analysisResponse.ok) {
                throw new Error('Erreur lors de la récupération de l\'analyse');
            }

            const analysisData = await analysisResponse.json();
            setOverviewContent(analysisData.response);
            localStorage.setItem(overviewKey, analysisData.response);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'analyse', error);
            setOverviewContent('Erreur de chargement');
        }
    };

    return (
        <div className='display-line'>
            <p>{overviewContent}</p>
            {overviewContent === 'No data available' && (
                <button onClick={() => {

                    fetchAnalysis();
                }}>Réessayer</button>
            )}
        </div>
    );
};

export default OverviewReportCard;
