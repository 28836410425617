import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UploadCSV from '../../pages/creation_compte/UploadCSV';
import DownloadCSV from '../../pages/creation_compte/DonwloadCSV';
import CreateEmployes from '../../pages/creation_compte/CreateEmployes';
import ReloadData from '../../pages/creation_compte/ReloadData';


const RoutesCreateCSV = () => (
  <Routes>
        <Route path="company/upload" element={<UploadCSV/>}/>
        <Route path="company/download" element={<DownloadCSV/>}/>
        <Route path="company" element={<CreateEmployes/>}/>
        <Route path="company/reload" element={<ReloadData/>}/>

  </Routes>
);

export default RoutesCreateCSV;
