import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Tableau.css';

const Tableau = ({
    data,
    columns,
    visibleColumns,
    setVisibleColumns,
    sortConfig,
    onSort,
    onRowClick,
    updateDates,
    onUpdateClick,
    pageKey, // unique key for each page
    hideFilter = false, // hideFilter prop, default is false
    hideSelect = false, // hideSelect prop, default is false
}) => {
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem(`filters_${pageKey}`);
        return savedFilters ? JSON.parse(savedFilters) : [];
    });
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page
    const tableRef = useRef(null);

    useEffect(() => {
        localStorage.setItem(`filters_${pageKey}`, JSON.stringify(filters));
    }, [filters, pageKey]);

    const getUniqueValues = (columnKey) => {
        const uniqueValues = data
            .map(item => item[columnKey])
            .filter((value, index, self) => self.indexOf(value) === index);
        return uniqueValues;
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return faSort;
        return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
    };

    const handleSort = (key) => {
        onSort(key);
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            setFilters([...filters, currentFilter]);
            setCurrentFilter({ column: '', value: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace(/\s/g, ' ').replace(/,/g, ' ');
    };

    const formatDate = (value) => {
        if (value == null) {
            return ''; // Return an empty string if the value is null or undefined
        }
        const date = new Date(value);
        if (!isNaN(date)) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        } else {
            return 'A définir'; // Return a default message for invalid dates
        }
    };

    const getFormattedValue = (key, value) => {
        if (key === 'budget' || key === 'salary_max') {
            return formatCurrency(value);
        }
        if (key === 'last_update' || key === 'arrival_month' || key === 'job_opening_date' || key === 'FORECAST_arrival_month' || key === 'FORECAST_job_opening_date'|| key === 'FORECAST_arrival_month_init') {
            return formatDate(value);
        }
        return value;
    };

    const getCellColorClass = (key, value) => {
        if (key === 'status') {
            switch (value) {
                case '0.Not Started':
                    return 'status-not-started';
                case '1.Job Opened':
                    return 'status-job-opened';
                case '2.Screening':
                    return 'status-screening';
                case '3.Interviewing':
                    return 'status-interviewing';
                case '4.Interviewing 2':
                    return 'status-interviewing-2';
                case '5.Offer Extended':
                    return 'status-offer-extended';
                case '6.Offer Accepted':
                    return 'status-offer-accepted';
                case '7.Arrived':
                    return 'status-arrived';
                default:
                    return '';
            }
        }
        return '';
    };

    const getPercentageFillStyle = (key, value) => {
        if (key.includes('percentage') && typeof value === 'number') {
            const roundedValue = Math.round(value / 10) * 10; // Arrondir à la dizaine la plus proche
            return {
                background: `linear-gradient(to right, #EBA49B40 ${roundedValue}%, transparent ${roundedValue}%)`,
            };
        }
        return {};
    };

    const handleColumnVisibilityChange = (columnKey) => {
        setVisibleColumns((prevColumns) => {
            const updatedColumns = prevColumns.includes(columnKey)
                ? prevColumns.filter((key) => key !== columnKey)
                : [...prevColumns, columnKey];
            let pageUrl = window.location.pathname;
            let baseUrl = pageUrl.match(/\/[^\/]+\/[^\/]+/)[0]; // Matche les deux premiers segments de l'URL
            localStorage.setItem(`visibleColumns_${baseUrl}`, JSON.stringify(updatedColumns));

            
            return updatedColumns;
        });
    };

    const startResizing = (event, columnKey) => {
        const startX = event.clientX;
        const startWidth = event.target.parentElement.offsetWidth;

        const handleMouseMove = (moveEvent) => {
            const newWidth = startWidth + (moveEvent.clientX - startX);
            if (newWidth > 100) {
                event.target.parentElement.style.width = `${newWidth}px`;
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const applyFilters = (data) => {
        return filters.reduce((filteredData, filter) => {
            return filteredData.filter(item => item[filter.column] === filter.value);
        }, data);
    };

    const filteredData = applyFilters(data);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className="table-container">
            {!hideFilter && (
                <div className="card-full">
                    <h5>Filter by</h5>
                    <div className="display-line dropdown">
                        <select className="dropdown" name="column" onChange={handleFilterChange} value={currentFilter.column}>
                            <option value="">Select Column</option>
                            {columns.map((column) => (
                                <option key={column.key} value={column.key}>
                                    {column.label}
                                </option>
                            ))}
                        </select>
                        <select className="dropdown" name="value" onChange={handleFilterChange} value={currentFilter.value} disabled={!currentFilter.column}>
                            <option value="">Select Value</option>
                            {currentFilter.column && getUniqueValues(currentFilter.column).map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <button type="submit" onClick={handleAddFilter} className="btn-secondary" disabled={!currentFilter.column || !currentFilter.value}>Add Filter</button>
                    </div>
                    <div className="display-line">
                        {filters.map((filter, index) => (
                            <div key={index} className="filter-tag">
                                {columns.find(column => column.key === filter.column)?.label}: {filter.value}
                                <button className="filter-tag-btn" type="button" onClick={() => handleRemoveFilter(index)}>x</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
           
            <div className='card-full'>
                {!hideSelect && (
                    <div className="display-line">
                        <button className='btn-secondary' onClick={() => setShowColumnSelector(!showColumnSelector)}>
                            {showColumnSelector ? 'Hide' : 'Show'} Column Selector
                        </button>
                        {showColumnSelector && (
                            <form className="display-line">
                                {columns.map((column) => (
                                    <div className="display-line" key={column.key}>
                                        <label className='checkbox'>
                                            <input
                                                type="checkbox"
                                                checked={visibleColumns.includes(column.key)}
                                                onChange={() => handleColumnVisibilityChange(column.key)}
                                            />
                                            <span className={visibleColumns.includes(column.key) ? 'selected' : ''}>
                                                {column.label}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </form>
                        )}
                    </div>
                )}
                
                <div className='table-container'>
                    <table className="table-custom" ref={tableRef}>
                        <thead>
                            <tr>
                                {columns
                                    .filter((column) => visibleColumns.includes(column.key))
                                    .map((column) => (
                                        <th key={column.key}>
                                            <div
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                onClick={() => handleSort(column.key)}
                                            >
                                                {column.label} <FontAwesomeIcon icon={getSortIcon(column.key)} />
                                                <div className="resizer" onMouseDown={(e) => startResizing(e, column.key)} />
                                            </div>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item) => (
                                <tr className="clickable" key={item.id} onClick={() => onRowClick(item)}>
                                    {columns
                                        .filter((column) => visibleColumns.includes(column.key))
                                        .map((column) => (
                                            <td
                                                key={column.key}
                                                className={getCellColorClass(column.key, item[column.key])}
                                                style={getPercentageFillStyle(column.key, item[column.key])}
                                            >
                                                {column.key === 'update' ? (
                                                    updateDates[item.id] ? (
                                                        <span>{updateDates[item.id]}</span>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onUpdateClick(item.id);
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    )
                                                ) : (
                                                    column.render
                                                        ? column.render(item[column.key], item)
                                                        : getFormattedValue(column.key, item[column.key])
                                                )}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="page-nav">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className='icon-button'
                    >
                        &lt; Previous
                    </button>
                    <span className="page-nav police">{currentPage}/{totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className='icon-button'
                    >
                        Next &gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Tableau;
