import React, { useState, useEffect } from 'react';

const HomeOverview = () => {
  const [turnoverChange, setTurnoverChange] = useState(null);

  const fetchData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');

      const response = await fetch(`${apiUrl}/api/turnover/semestre`, {
        headers: {
          'Authorization': `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données de turnover');
      }

      const data = await response.json();
      if (data && data.length > 1) {
        const lastPeriodTurnover = parseFloat(data[data.length - 1].taux_turnover.replace('%', ''));
        const previousPeriodTurnover = parseFloat(data[data.length - 2].taux_turnover.replace('%', ''));
        const change = ((lastPeriodTurnover - previousPeriodTurnover) / previousPeriodTurnover * 100).toFixed(2);
        
        setTurnoverChange(change);
      } else {
        setTurnoverChange(0); // Assume no change if not enough data
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données de turnover', error);
      setTurnoverChange(null); // Set to null on error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderMessage = () => {
    if (turnoverChange === null) {
        return 'Chargement...';
    } else if (turnoverChange === 0) {
        return 'Le taux de turnover est stable depuis le dernier semestre.';
    } else if (turnoverChange > 0 && turnoverChange <= 100) {
        return `Depuis le dernier semestre, le taux de turnover a augmenté de ${turnoverChange}%.`;
    } else if (turnoverChange > 100) {
        return `Depuis le dernier semestre, le taux de turnover a augmenté de ${turnoverChange}%. Cette variation est assez inquiétante...`;
    } else if (turnoverChange < 0) {
        return `Depuis le dernier semestre, le taux de turnover a diminué de ${Math.abs(turnoverChange)}%.`;
    } else {
        return 'Informations sur le turnover non disponibles.';
    }
};


  return (
    <h3>{renderMessage()}</h3>
  );
};

export default HomeOverview;
