import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';  // Importer l'icône d'exclamation
import logo_txt from "../assets/logo_txt.png";
import "../styles/Login.css";
import "../styles/Sidebar.css";
import "../styles/Button.css";

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false); // Nouvel état pour le succès de la réinitialisation
    const [csrfToken, setCsrfToken] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const getCsrfTokenFromCookies = () => {
            let cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i].trim();
                    if (cookie.substring(0, 'csrftoken'.length + 1) === ('csrftoken' + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring('csrftoken'.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        };

        const fetchCsrfToken = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            try {
                console.log("Fetching CSRF token from:", `${apiUrl}/set-csrf-token/`);
                const response = await fetch(`${apiUrl}/set-csrf-token/`, {
                    method: 'GET',
                    credentials: 'include',
                });
                console.log("CSRF fetch response status:", response.status);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // Attendez que le navigateur enregistre les cookies
                setTimeout(() => {
                    const token = getCsrfTokenFromCookies();
                    console.log("Fetched CSRF token:", token);
                    if (token) {
                        setCsrfToken(token);
                    } else {


                    }
                }, 1000); // Ajustez le délai si nécessaire

            } catch (error) {
                console.error("Erreur lors de la définition du token CSRF:", error);
            }
        };

        const csrf = getCsrfTokenFromCookies();
        if (!csrf) {
            fetchCsrfToken();
        } else {
            setCsrfToken(csrf);
        }
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError('');
    };

    const fetchUserDataAndEntreprises = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!userData.username) {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/api/employe-log/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Erreur de réseau ou réponse non valide');
                }

                userData = await response.json();
                localStorage.setItem('userData', JSON.stringify(userData));
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
                return;
            }
        }
    };

    const deleteCookie = (name) => {
        document.cookie = `${name}=; Max-Age=0; path=/; domain=${window.location.hostname}; secure; SameSite=Strict`;
    };

    const clearStorageAndCookies = () => {
        // Supprimez uniquement les cookies nécessaires
        deleteCookie('csrftoken');
        deleteCookie('sessionid');
        localStorage.removeItem('csrfToken');
        localStorage.removeItem('token');
        // Ne videz pas complètement le localStorage
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearStorageAndCookies(); // Effacez uniquement les cookies et le localStorage nécessaires

        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            console.log("Submitting login form with CSRF token:", csrfToken);
            const response = await fetch(`${apiUrl}/api/login/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
            //        'X-CSRFToken': csrfToken // Ajoutez le token CSRF ici
                },
                body: JSON.stringify(formData),
             //   credentials: 'include'
            });

            if (!response.ok) {
                if (response.status === 401) {
                    setError("Invalid Credentials. Please try again.");
                    amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: 'Invalid Credentials' });
                } else {
                    throw new Error(`HTTP Error: ${response.status}`);
                }
            }

            const json = await response.json();

            if (json.token) {
                localStorage.setItem('token', json.token);
                await fetchUserDataAndEntreprises(); // Attendre que les données soient récupérées

                // Log the successful login event
                amplitude.getInstance().logEvent('User LogIn', { statut: "success", email: formData.email });

                navigate('/');
            } else {
                setError("Unable to log in with provided credentials.");
                amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: 'Unknown Error' });
            }
        } catch (error) {
            console.error("Error sending data:", error);
            setError("Unable to log in with provided credentials.");
            amplitude.getInstance().logEvent('User LogIn', { statut: "failed", email: formData.email, reason: error.message });
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            const formData = new URLSearchParams();
            formData.append('user_email', email);
            console.log("csrfToken", csrfToken);
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/send-reset-password/`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-CSRFToken': csrfToken
                },
                credentials: 'include',
                body: formData
            });

            if (response.ok) {
                setResetSuccess(true); // Indiquer que l'email de réinitialisation a été envoyé
                amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'success' });
            } else {
                amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'failed' });
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
        } catch (error) {
            console.error("Erreur lors de la demande de réinitialisation:", error);
            alert("Impossible d'envoyer l'email de réinitialisation.");
            amplitude.getInstance().logEvent('User EmailResetPassword', { email, statut: 'failed', reason: error.message });
        }
    };

    const toggleResetForm = () => {
        setShowResetForm(!showResetForm);
        setResetSuccess(false); // Réinitialiser l'état de succès lors du basculement du formulaire
        setError(''); // Hide error message when toggling the reset form
    };

    return (
        <div className='padding-100'>
            <form onSubmit={showResetForm ? handlePasswordReset : handleSubmit} className="login-form">
                <div>
                    <img src={logo_txt} alt="Logo de l'entreprise" className='logo'></img>
                </div>
                {!showResetForm ? (
                    <>
                        <div className="form-column">
                            <label htmlFor="username">Email</label>
                            <input type="email" name="email" className="form-input" value={formData.email} onChange={handleChange} />
                        </div>
                        <div className="form-column">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" name="password" className="form-input" value={formData.password} onChange={handleChange} />
                        </div>
                        <div className="line-margin-10">
                            <button type="submit" className="btn-primary">Se connecter</button>
                        </div>
                        <div className="line-margin-10">
                            <button type="button" onClick={toggleResetForm} className="btn-secondary">Mot de passe oublié ?</button>
                        </div>
                    </>
                ) : (
                    resetSuccess ? (
                        <div className="success-message">
                            <p>Vérifiez votre email pour obtenir les instructions sur la réinitialisation de votre mot de passe. N'oubliez pas de regarder dans votre dossier spam si vous ne trouvez pas l'email.</p>
                        </div>
                    ) : (
                        <>
                            <div className="form-column">
                                <input type="email" placeholder="Votre email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" />
                            </div>
                            <div className="line-margin-10">
                                <button type="submit" className="btn-primary">Envoyer email de réinitialisation</button>
                            </div>
                            <div className="line-margin-10">
                                <button onClick={toggleResetForm} className="btn-secondary">Retour à Connexion</button>
                            </div>
                        </>
                    )
                )}
                {error && (
                    <div className="error-message">
                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                        {error}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Login;
