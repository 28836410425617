import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title } from 'chart.js';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import OverviewCard from '../../../components/cards/OverviewCard';

// Configuration pour ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

const InsightStaffLateTeam = () => {
    const [data, setData] = useState([]);
    const promptContext = "Tu vas recevoir des données qui représentent les retards de recrutement. Elles sont group by team.";

    useEffect(() => {
        const storageKey = 'InsightStaffLateTeam';
        const savedData = localStorage.getItem(storageKey);

        if (savedData) {
            const { data, timestamp } = JSON.parse(savedData);
            const oneDay = 24 * 60 * 60 * 1000;

            if (new Date() - new Date(timestamp) < oneDay) {
                setData(data);
                return;
            }
        }

        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const url = `${apiUrl}/openposition/teams_behind_recruitment_goals/`;
            try {
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data: data, timestamp: new Date() }));
                setData(data);

            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, []);

    const generateRandomColor = () => {
        const r = 255;
        const g = Math.floor(Math.random() * 101);
        const b = Math.floor(Math.random() * 101);
        return `rgba(${r}, ${g}, ${b}, 0.6)`;
    };

    const backgroundColors = data.map(() => generateRandomColor());
    const borderColors = backgroundColors.map(color => color.replace('0.6', '1'));

    const chartData = {
        labels: data.map(item => item.team),
        datasets: [{
            label: 'Positions derrière l\'objectif',
            data: data.map(item => item.positions_behind),
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1
        }]
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Teams'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Difference between forecast and real'
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            title: {
                display: true,
                text: 'Difference recrutements between forecast and real at the day of today'
            }
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <OverviewCard overviewKey={'OverviewInsightStaffLateTeam'} storageKey={'InsightStaffLateTeam'} promptContext={promptContext} />
                <div className='card-full margin-b-40'>
                    {chartData && <Bar data={chartData} options={chartOptions} />}
                </div>
            </div>
        </div>
    );
};

export default InsightStaffLateTeam;
