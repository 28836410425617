import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import OverviewCard from '../../../components/cards/OverviewCard';

// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// Fonction pour formater les dates en 'YYYY-MM-DD'
const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphStaffMasseSalariale = () => {
    const promptContext = "You will receive data that represents the evolution of the masse salariale.";

    const today = new Date();
    const lastYear = new Date();
    const nextYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);
    nextYear.setFullYear(today.getFullYear() + 1);

    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(nextYear));
    const [fetchDataFlag, setFetchDataFlag] = useState(false);

    const processSalaryData = (salaryMinData, salaryMaxData) => {
        const labels = salaryMinData.map(item => item.periode);

        const salaryMinDataset = {
            label: 'Masse Salariale Min',
            data: salaryMinData.map(item => parseFloat(item.masse_salariale_min)),
            fill: true,
            backgroundColor: '#93c2db40',
            borderColor: '#93c2db',
            tension: 0.1
        };

        const salaryMaxDataset = {
            label: 'Masse Salariale Max',
            data: salaryMaxData.map(item => parseFloat(item.masse_salariale_max)),
            fill: true,
            backgroundColor: '#7289da40',
            borderColor: '#7289da',
            tension: 0.1
        };

        setChartData({
            labels: labels,
            datasets: [salaryMinDataset, salaryMaxDataset]
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            let url;

            try {
                // Fetch Salary Min Data
                url = `${apiUrl}/openposition/masse_salariale_evolution_min/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}`;
                const salaryMinResponse = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                if (!salaryMinResponse.ok) {
                    throw new Error('Erreur lors de la récupération des données de masse salariale min');
                }
                const salaryMinData = await salaryMinResponse.json();

                // Fetch Salary Max Data
                url = `${apiUrl}/openposition/masse_salariale_evolution_max/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}`;
                const salaryMaxResponse = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                if (!salaryMaxResponse.ok) {
                    throw new Error('Erreur lors de la récupération des données de masse salariale max');
                }
                const salaryMaxData = await salaryMaxResponse.json();

                // Combine Salary Data
                processSalaryData(salaryMinData, salaryMaxData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        if (fetchDataFlag) {
            fetchData();
            setFetchDataFlag(false);
        }
    }, [fetchDataFlag, startDate, endDate, groupOption]);

    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleValidateClick = () => {
        setFetchDataFlag(true);
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <OverviewCard overviewKey={'OverviewGraphStaffEffectif'} storageKey={'GraphStaffEffectif'} promptContext={promptContext} />
                <div className='card-full'>
                    <div className="display-line">
                        <div className="display-line">
                            Start Date
                            <input type="date" className="form-input" value={startDate} onChange={handleStartDateChange} />
                        </div>
                        <div className="display-line">
                            End Date
                            <input type="date" className="form-input" value={endDate} onChange={handleEndDateChange} />
                        </div>
                        <div className="display-line">
                            Group By
                            <select value={groupOption} onChange={handleGroupChange}>
                                <option value="month">Month</option>
                                <option value="quarter">Quarter</option>
                            </select>
                        </div>
                        <button type='submit' onClick={handleValidateClick}>Validate</button>
                    </div>
                </div>

                <div className='card-full margin-b-40'>
                    {chartData && <Line data={chartData}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Period'
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    title: {
                                        display: true,
                                        text: 'Masse Salariale (€)'
                                    }
                                }
                            },
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'bottom',
                                },
                                title: {
                                    display: true,
                                    text: "Evolution de la Masse Salariale"
                                }
                            },
                        }}
                    />}
                </div>
            </div>
        </div>
    );
};

export default GraphStaffMasseSalariale;
