import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import IndexHF from '../../components/kpi/diversity/IndexHF';
import TurnoverRate from '../../components/kpi/TurnoverRate';
import OverviewReportCard from '../../components/cards/OverviewReportCard';

const ReportsPage = () => {
    const [anchorId, setAnchorId] = useState('');
    const [turnoverData, setTurnoverData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const promptContext = "Tu vas recevoir des données qui représentent le taux de turnover de l'entreprise. Tu dois expliquer ce qui a varié depuis la dernière période.";
    const promptContextINDEXHF = "Tu vas recevoir des données qui représentent la proportion homme de femme dans l'entreprise. Tu dois expliquer ce qui a varié depuis la dernière période.";
    const isInteractive = false; 
    
    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            setAnchorId(hash.replace('#', ''));
        };
        handleHashChange();

        const fetchData = async () => {
            setLoading(true);
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const token = localStorage.getItem('token');


                const response = await fetch(`${apiUrl}/api/turnover/all/?date_fin=${anchorId}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données de distribution de risque');
                }

                const data = await response.json();
                setTurnoverData(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de distribution de risque', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, [anchorId]); // Dépendance à anchorId pour recharger les données à chaque changement d'URL

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />

                <div className='container-full-report'>
                             <h5>Reports du {anchorId}</h5>
                             <div></div>


                            <h3>Turnover rate</h3>
                            <div></div>
                            <OverviewReportCard overviewKey={`OverviewGraphTurnoverRate-${anchorId}`} storageKey={'GraphTurnoverRate'} promptContext={promptContext} isInteractive={isInteractive} />
                            <TurnoverRate turnoverData={turnoverData} />

                            <h3>Diversité</h3>
                            <div></div>

                            
                                <OverviewReportCard overviewKey={`OverviewGraphIndexHF-${anchorId}`}   storageKey={'GraphIndexHF'} promptContext={promptContextINDEXHF}  isInteractive={isInteractive}/>

                                <IndexHF />



                </div>
            </div> 
        </div>
    );
};

export default ReportsPage;
