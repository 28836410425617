import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../../cards/HomeCards';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const ForecastEmployes = () => {
    const [chartData, setChartData] = useState(null);
    const [forecast12months, setForecast12months] = useState('Chargement...');
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph: 'Forecast Employes',
            description: 'New arrivals during current month, and evolution on 12 months.',
        });

        navigate('/staffingplan/graph/effectif');
    };

    const processChartData = (data) => {
        const parseDate = (dateString) => {
            const [startPeriod] = dateString.split(" - ");
            const [month, year] = startPeriod.split("/");
            return new Date(year, month - 1);
        };

        const last12MonthsData = data.filter(item => {
            const itemDate = parseDate(item.periode);
            const now = new Date();
            const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), 1);
            return itemDate >= lastYear && itemDate <= now;
        });

        const labels = last12MonthsData.map(item => item.periode);
        const employeesData = last12MonthsData.map(item => parseFloat(item.nombre_new_employes));

        const dataset = {
            label: 'Effectif',
            data: employeesData,
            fill: true,
            backgroundColor: '#EBA49B80',
            borderColor: '#EBA49B',
            borderWidth: 2,
            tension: 0.1
        };

        setChartData({
            labels,
            datasets: [dataset]
        });

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const formattedCurrentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
        const currentMonthYear = `${formattedCurrentMonth}/${currentYear}`;

        const currentData = data.find(item => {
            const [startMonth, startYear] = item.periode.split(" - ")[0].split("/");
            return `${startMonth}/${startYear}` === currentMonthYear;
        });

        if (currentData) {
            setForecast12months(`${currentData.nombre_new_employes} arrivals`);
        } else {
            setForecast12months('No data');
        }
    };

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const storageKey = 'EffectifData';
        const oneDay = 24 * 60 * 60 * 1000;

        const now = new Date();
        const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), 1);

        const startDate = lastYear.toISOString().split('T')[0];

        const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];

        const cachedData = localStorage.getItem(storageKey);

        const isCacheValid = (timestamp) => {
            return new Date() - new Date(timestamp) < oneDay;
        };

        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);

            if (isCacheValid(timestamp)) {
                processChartData(data);
                return;
            }
        }

        try {
            const url = `${apiUrl}/openposition/historique_et_estimations/?start_date=${startDate}&end_date=${endDate}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données d\'effectif');
            }
            const responseData = await response.json();
            const { data } = responseData;

            if (Array.isArray(data)) {
                localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
                processChartData(data);
            } else {
                throw new Error('Data fetched is not an array');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const chartOptions = {
        scales: {
            y: {
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
            x: {
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0,
            },
            line: {
                borderWidth: 3,
            }
        },
        maintainAspectRatio: false,
    };

    return (
        <HomeCards
            text={`New arrivals during current month, and evolution on 12 months.`}
            kpi={forecast12months}
            chart={chartData && <Bar data={chartData} options={chartOptions} height={65} width={1000} />}
            onClick={handleOnClick}
        />
    );
};

export default ForecastEmployes;
