import React, { useState } from "react";
import '../../styles/Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowDownLong } from '@fortawesome/free-solid-svg-icons';

const DataCleanCard = ({ titre, pourcentage, list }) => {
  // State to manage whether the card is expanded or not
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the card expansion
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div onClick={toggleExpansion} className="card-full clickable">
      <div className="display-line">
        <div className="card-insight-titre">{titre} - Complétion: {pourcentage}</div>
        <FontAwesomeIcon
          icon={isExpanded ? faArrowDownLong : faArrowRightLong}
          className="card-insight-icon"
        />
      </div>

      {/* Conditionally render the content based on `isExpanded` */}
      {isExpanded && (
        <div className="display-column">
          <div className="card-insight-separator"></div>
          {list.length > 0 ? (
            <ul>
              {list.map((item, index) => (
                <li key={index}>{`${item.first_name} ${item.last_name}`}</li>
              ))}
            </ul>
          ) : (
            <p>Aucun employé avec des données incomplètes pour cette catégorie.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default DataCleanCard;
