import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/ButtonSidebar.css'; // Assurez-vous de créer ce fichier CSS pour styliser le bouton
import { useNavigate, useLocation } from 'react-router-dom';


const ButtonSidebar = ({ text, icon, redirectTo, isCollapsed  }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = location.pathname === redirectTo;
    const handleClick = () => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    };
    return (
        <button className={`button-sidebar ${isActive ? 'active' : ''}`} onClick={handleClick} >
            <FontAwesomeIcon icon={icon} />

            {!isCollapsed && <span className="button-text">{text}</span>}
        </button>


        
    );
};

export default ButtonSidebar;
