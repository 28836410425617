import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../styles/Onboarding.css';

const UploadCSV = () => {
  const [files, setFiles] = useState([]);
  const [emailRecipient, setEmailRecipient] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Extraction de l'email depuis l'URL
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    if (email) {
      setEmailRecipient(email);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // Ajout des fichiers acceptés à l'état local
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  // Integration de useDropzone pour gérer le glisser-déposer
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: true
  });

  const getCsrfToken = () => {
    // Fonction pour récupérer le CSRF token
    let csrfToken = null;
    const csrfCookieName = 'csrftoken=';
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(csrfCookieName)) {
                csrfToken = decodeURIComponent(cookie.substring(csrfCookieName.length));
                break;
            }
        }
    }
    return csrfToken;
  };

  const sendSuccessEmail = async () => {
    // Préparation et envoi de la demande d'envoi d'email
    const emailData = {
      to_email: emailRecipient, // Utilisation de l'email récupéré
      template_alias: 'welcome-2', // Remplacez par votre alias de template
      // Ajoutez ici d'autres données si nécessaire
    };

    const formData = new URLSearchParams();
    for (const key in emailData) {
      formData.append(key, emailData[key]);
    }

    try {
      const csrfToken = getCsrfToken();
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/email/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": csrfToken
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

      console.log('Email envoyé avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Gestion du formulaire et appel à l'API
    if (!files.length) {
      setMessage("Veuillez sélectionner au moins un fichier CSV.");
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files[]', file);
    });

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/csvfile/`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage("Fichiers CSV téléchargés avec succès.");
        await sendSuccessEmail(); // Appel de la fonction d'envoi d'email
      } else {
        setMessage("Erreur lors du téléchargement des fichiers CSV.");
      }
    } catch (error) {
      setMessage(`Erreur de téléchargement : ${error.message}`);
    }
  };

  // Rendu du composant avec le formulaire de téléchargement
  return (
    <div className='container-right'>
      <div className='container-center'>
        <h4>Télécharger des fichiers CSV</h4>
        <form onSubmit={handleSubmit}>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Relâchez les fichiers ici...</p> :
                <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
            }
          </div>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
          <button type="submit">Télécharger</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default UploadCSV;
