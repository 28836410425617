import React, { useState } from 'react';

const DownloadCSV = () => {
  const [fileId, setFileId] = useState('');

  const handleDownload = async () => {
    if (!fileId) {
      alert("Veuillez entrer un ID de fichier.");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/api/csvfile/download/${fileId}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const fileName = fileId + ".csv"; // Nom de fichier simplifié, ajustez selon la réponse de votre serveur
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        throw new Error('Réponse du serveur non OK lors du téléchargement');
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier CSV :", error);
      alert("Erreur lors du téléchargement du fichier CSV.");
    }
  };

  return (
    <div>
      <h2>Télécharger un fichier CSV par ID</h2>
      <div>
        <input
          type="text"
          value={fileId}
          onChange={(e) => setFileId(e.target.value)}
          placeholder="Entrez l'ID du fichier CSV"
        />
        <button onClick={handleDownload}>Télécharger</button>
      </div>
    </div>
  );
};

export default DownloadCSV;
