import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend } from 'chart.js';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import FilterSection from '../../../components/recrutement/FilterSection';
import DateSection from '../../../components/recrutement/DateSection';
import ChartSection from '../../../components/recrutement/ChartSection';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend);

const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const colorPalette = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    // Ajoutez plus de couleurs si nécessaire
];

const getColorForTeam = (teamName, teamNames) => {
    const index = teamNames.indexOf(teamName);
    return colorPalette[index % colorPalette.length];
};

const GraphStaffEffectif = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const defaultStartDate = new Date(currentYear, 0, 1);
    const defaultEndDate = new Date(currentYear, 11, 31);

    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(defaultStartDate));
    const [endDate, setEndDate] = useState(formatDate(defaultEndDate));
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });

    const processChartData = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const { periode, teams, nombre_new_employes } = item;
            if (!acc[periode]) {
                acc[periode] = { teams: {}, new_employees: nombre_new_employes };
            }

            if (Array.isArray(teams)) {
                teams.forEach(team => {
                    if (!acc[periode].teams[team.team_name]) {
                        acc[periode].teams[team.team_name] = 0;
                    }
                    acc[periode].teams[team.team_name] += team.team_count;
                });
            }

            return acc;
        }, {});

        const labels = Object.keys(groupedData);
        const teamNames = [...new Set(data.flatMap(item => item.teams ? item.teams.map(team => team.team_name) : []))];

        const datasets = teamNames.map(teamName => {
            const color = getColorForTeam(teamName, teamNames);
            return {
                label: teamName,
                data: labels.map(label => groupedData[label].teams[teamName] || 0),
                type: 'bar',
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
            };
        });

        const lineDataset = {
            label: 'New Employees',
            data: labels.map(label => groupedData[label].new_employees || 0),
            type: 'line',
            fill: false,
            borderColor: '#FF5733',
            borderWidth: 2,
            tension: 0.1
        };

        setChartData({
            labels,
            datasets: [...datasets, lineDataset]
        });
    };

    const fetchFilterOptions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/historique_et_estimations/`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) {
                throw new Error('Error fetching filter options');
            }

            const data = await response.json();
            setFilterOptions(data.filters);
        } catch (error) {
            console.error('Error fetching filter options', error);
        }
    };

    const fetchData = async () => {
        const storageKey = `GraphStaffEffectif_${startDate}_${endDate}_${groupOption}_${JSON.stringify(filters)}`;
        const cachedData = localStorage.getItem(storageKey);
        const oneDay = 24 * 60 * 60 * 1000;

        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }

        const filterParams = filters.map(filter => `${filter.column}=${filter.value}`).join('&');
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/historique_et_estimations/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}&${filterParams}`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) {
                throw new Error('Error fetching workforce data');
            }

            const { data } = await response.json();
            localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
            processChartData(data);
        } catch (error) {
            console.error('Error fetching workforce data', error);
        }
    };

    useEffect(() => {
        const initializePage = async () => {
            await fetchFilterOptions();
            await fetchData(); // Fetch data when the page loads
        };

        initializePage();
    }, []); // Fetch filter options and data once when the component mounts

    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleApplyDates = () => {
        fetchData();
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            const displayValue = currentFilter.column === 'team'
                ? filterOptions.teams.find(team => team.id === parseInt(currentFilter.value, 10))?.name
                : currentFilter.value;

            setFilters([...filters, { ...currentFilter, displayValue }]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
        fetchData();
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />

                <FilterSection
                    currentFilter={currentFilter}
                    filterOptions={filterOptions}
                    handleFilterChange={handleFilterChange}
                    handleAddFilter={handleAddFilter}
                    filters={filters}
                    handleRemoveFilter={handleRemoveFilter}
                />

                <DateSection
                    startDate={startDate}
                    endDate={endDate}
                    groupOption={groupOption}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    handleGroupChange={handleGroupChange}
                    handleApplyDates={handleApplyDates}
                />

                <ChartSection
                    chartData={chartData}
                    title="Arrival per period (only 7.Arrived)"
                    xlegend="Period"
                    ylegend="Employees"
                    stack={true}
                />
            </div>
        </div>
    );
};

export default GraphStaffEffectif;
