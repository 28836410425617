import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { faBrain, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/Graph.css";
import OverviewCard from '../../components/cards/OverviewCard';

// Configuration pour ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const InsightLeaveReasons = () => {
    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('Sexe');
    const promptContext = "Tu vas recevoir des données qui représentent les raisons de départs de l'entreprise. Elles sont group by différentes propriétés.";


    const processChartData = (data) => {
        const groupData = data[groupOption] || {};
      
        // Créer les labels et les datasets directement à partir des données
        const labels = Object.keys(groupData);
        const allReasons = Object.values(groupData).flatMap(Object.keys);
        const uniqueReasons = [...new Set(allReasons)];
      
        const datasets = uniqueReasons.map((reason) => {
          const datasetData = labels.map((label) => groupData[label][reason] || 0);
          return {
            label: reason,
            data: datasetData,
            backgroundColor: colors[labels.indexOf(reason) % colors.length],
            borderColor: colors[labels.indexOf(reason) % colors.length],
            borderWidth: 1
          };
        });
      
        setChartData({
          labels,
          datasets
        });
      };

    useEffect(() => {
        const storageKey = 'InsightLeaveReasons';
        const savedData = localStorage.getItem(storageKey);
        
        if (savedData) {
            const { data, timestamp } = JSON.parse(savedData);
            const oneDay = 24 * 60 * 60 * 1000;

            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);

                return;
            }
        }

        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const url = `${apiUrl}/api/insight/leave-reasons/`;
            try {
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data: data, timestamp: new Date() }));
                processChartData(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, [groupOption]);



    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        }
    };
    
    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <OverviewCard overviewKey={'OverviewInsightLeaveReasons'}  storageKey={'InsightLeaveReasons'} promptContext={promptContext}/>
    
                <div className='card-full'>
                    <div className='display-line'>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <h5>Grouper par :</h5>
                        <select className="custom-select" onChange={handleGroupChange} value={groupOption}>
                        <   option value="Global">Vue Globale</option>
                            <option value="Âge">Âge</option>
                            <option value="Ancienneté">Ancienneté</option>
                            <option value="Sexe">Sexe</option>
                            <option value="Equipe">Equipe</option>
                            <option value="Localisation">Localisation</option>
                        </select>
                    </div>
                </div>
                <div className='card-full margin-b-40'>
                    {chartData && <Bar data={chartData} options={chartOptions} />}
                </div>
            </div>
        </div>
    );
};

export default InsightLeaveReasons;
