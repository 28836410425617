import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import colors from '../../assets/colors';
import "../../styles/Home.css";
import "../../styles/Card.css";

import NineBoxMatrix from '../../components/perf/NineBoxMatrix';
import SelectReview from '../../components/perf/SelectReview';
import ImportPerf from '../../components/perf/ImportPerf';
import ListPerfReview from '../../components/perf/ListPerfReview';
import ReviewStatusCount from '../../components/perf/ReviewStatusCount';
import Callibration from '../../components/perf/Callibration';
import GroupCompensationReview from '../../components/perf/GroupCompensationReview';


const PerfPage = () => {
    const { reviewId } = useParams();
    const [activeTab, setActiveTab] = useState('Visualisation');
    const [chartData, setChartData] = useState({});
    const [error, setError] = useState('');
    const [data, setData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    const fetchAnalysis = useCallback(async () => {
        if (!reviewId) return;
        const analysisUrl = `${apiUrl}/api/perf/ninebox/${reviewId}/`;
        try {
            const response = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setData(data);
            processChartData(data);

            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightNineBox', JSON.stringify(dataToStore));
            
        } catch (error) {
            console.error('Error fetching analysis:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, [apiUrl, token, reviewId]);

    useEffect(() => {
        fetchAnalysis();
    }, [fetchAnalysis]);

    const processChartData = (data) => {
        if (!data || !data.Global) {
            console.error('Invalid data:', data);
            return;
        }

        const totalEmployees = Object.values(data.Global).reduce((sum, category) => sum + category.count, 0);

        const datasets = Object.keys(data.Global).map(key => ({
            label: key,
            data: [{
                x: data.Global[key].x,
                y: data.Global[key].y,
                r: data.Global[key].count 
            }],
            backgroundColor: '#FF000060',
            borderColor: '#FF0000'
        }));

        setChartData({ datasets, totalEmployees });
    };

    const renderSimpleTable = () => {
        return (
            <table className="table-custom">
                <thead>
                    <tr>
                        <th>Nom de Catégorie</th>
                        <th>Nombre de Salariés</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data.Global || {}).map((key, index) => (
                        <tr key={index}>
                            <td>{key}</td>
                            <td>{data.Global[key].count || '0'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const navigate = useNavigate();

  

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Import':
                return (
                    <div>
                        <ImportPerf/>
                    </div>
                );
            case 'Visualisation':
                return (
                    <div className='cards-container nowrap haut'>
                        {error && <p className="error">{error}</p>}
                        {chartData.datasets && (
                            <NineBoxMatrix chartData={chartData} />
                        )}
                        <div className='card-half-full secondary'>
                            {renderSimpleTable()}
                            
                        </div>
                    </div>
                );
           
            case 'Calibration':
                return (
                    <Callibration/>
                );
            case 'Compensation':
                return (

                    <GroupCompensationReview/>
                );
            case 'Validation':
                return (
                    <div className='line-container-10'>
                        <ReviewStatusCount/>
                        <ListPerfReview/>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <SelectReview/>
                {reviewId && (
                    <>
                        <div className="tab-menu">
                            <button
                                className={`tab-menu-btn ${activeTab === 'Import' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Import')}
                            >
                                Import
                            </button>
                            <button
                                className={`tab-menu-btn ${activeTab === 'Calibration' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Calibration')}
                            >
                                Calibration
                            </button>
                         
                            <button
                                className={`tab-menu-btn ${activeTab === 'Visualisation' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Visualisation')}
                            >
                                Visualisation
                            </button>
                            <button
                                className={`tab-menu-btn ${activeTab === 'Compensation' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Compensation')}
                            >
                                Compensation
                            </button>
                            <button
                                className={`tab-menu-btn ${activeTab === 'Validation' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Validation')}
                            >
                                Validation
                            </button>
                        </div>
                        <div className="card-insight-separator"> </div>
                        <div className="tab-content">
                            {renderTabContent()}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PerfPage;
