import React from 'react';
import { Line } from 'react-chartjs-2';

const ChartSection = ({ chartData, title, xlegend, ylegend, stack = false }) => {
    return (
        <div className='card-full margin-b-40'>
            {chartData && <Line data={chartData}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: stack, // Empile les barres si `stack` est true
                            title: {
                                display: true,
                                text: xlegend
                            }
                        },
                        y: {
                            stacked: stack, // Empile les barres si `stack` est true
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: ylegend 
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                        title: {
                            display: true,
                            text: title
                        }
                    },
                }}
            />}
        </div>
    );
};

export default ChartSection;
